<template>
  <v-container fluid>
    <mex-sperm-spinner
      v-if="dashboardDataLoading"
      :spinnerText="getDashboardDataLoadingSpinnerText"
    />
    <template v-else>
      <mex-heading :content="getDashboardHeading" />
      <v-row class="pt-4">
        <v-col cols="6">
          <clinic-dropdown :items="clinicNames" :value="selectedClinic" @selectedClinicChange="goToClinicViewDropdown">

          </clinic-dropdown>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="6" style="flex-direction: column">
          <mex-card cardClass="foreground" icon="mdi-hospital-building" title="Assigned Clinics">
            <clinics-table
              v-if="hasClinicReadPermission"
              :excludeHeaders="['OrganizationSystemUsers', 'lastContact', 'RegisterType.acronym']"
              :filterData="getFilterData"
            />
          </mex-card>
        </v-col>

        <v-col class="d-flex" cols="6" style="flex-direction: column">
          <mex-card cardClass="foreground" icon="mdi-bell-ring" title="Notifications">
            <v-row justify="end" class="ma-1">
              <v-col cols="auto">
                <mex-btn icon="mdi-refresh" iconOnly @click="fetchNotifications" />
              </v-col>
              <v-col cols="auto">
                <mex-btn
                  :content="fetchAll ? 'get lastest' : 'get all'"
                  @click="switchFetchAmount"
                />
              </v-col>
            </v-row>
            <mex-sheet
              class="notificationSheetInner"
              color="transparent"
              elevation="0"
              max-height="360px"
              rounded
              style="overflow-y: scroll; overflow-x: hidden"
            >
              <transition-group name="notificationTransition" tag="div">
                <v-row v-for="(notification, idx) in notifications" :key="`${idx}-notification`">
                  <v-col v-if="!notification.markedRead" cols="1">
                    <mex-p :content="`#${idx + 1}`" fontSize="overline" />
                  </v-col>
                  <v-col :cols="!notification.markedRead ? '11' : '12'" class="pa-0">
                    <v-alert
                      :class="!notification.markedRead ? 'notificationIn' : 'notificationOut'"
                      :color="getNotificationColor(notification)"
                      :icon="getNotificationIcon(notification)"
                      prominent
                    >
                      <v-row class="ml-2">
                        <v-col cols="10">
                          <v-row>
                            <mex-p
                              :content="getNotificationHeader(notification)"
                              class="mb-0"
                              fontWeight="bold"
                            />
                          </v-row>
                          <v-row class="mb-0">
                            <v-col class="pa-0" cols="auto">
                              <mex-p
                                :content="notification.message"
                                class="mb-0"
                                fontSize="overline"
                                fontWeight="bold-italic"
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mb-0 mt-0">
                            <v-col class="pa-0" cols="auto">
                              <mex-p
                                :content="$dateFormatter.convertJsonDate(notification.createdAt).full"
                                class="mb-0"
                                fontSize="overline"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="!notification.markedRead" align-self="center" cols="1">
                          <mex-btn
                            icon="mdi-email"
                            iconOnly
                            x-small
                            @click="updateNotification(notification)"
                          />
                        </v-col>
                        <v-col v-else align-self="center" cols="1">
                          <mex-btn
                            icon="mdi-email-open"
                            iconOnly
                            x-small
                            @click="updateNotification(notification)"
                          />
                        </v-col>
                        <v-col align-self="center" cols="1">
                          <v-row>
                            <mex-btn
                              v-if="idx > 0"
                              icon="mdi-arrow-up"
                              iconOnly
                              x-small
                              @click="switchUp(idx)"
                            />
                          </v-row>
                          <v-row>
                            <mex-btn
                              icon="mdi-close"
                              iconOnly
                              x-small
                              @click="deleteNotification(notification)"
                            />
                          </v-row>
                          <v-row>
                            <mex-btn
                              v-if="idx < notifications.length - 1"
                              icon="mdi-arrow-down"
                              iconOnly
                              x-small
                              @click="switchDown(idx)"
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </transition-group>
            </mex-sheet>
          </mex-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="6" style="flex-direction: column">
          <mex-card cardClass="foreground" icon="mdi-account" title="System User Settings">
            <mex-btn
              class="ma-5"
              content="Update your password"
              @click="showDialog = true; showUpdatePassword = true"
            />
            <mex-btn
              class="ma-5"
              content="Update your 2 FA Device"
              @click="showPasswordCheckDialog = true; show2Fak=true"
            />
            <mex-btn
              class="ma-5"
              content="Show Recovery Codes"
              @click="showPasswordCheckDialog = true; show2FakRecoveryCodes = true"
            />
          </mex-card>
        </v-col>
      </v-row>
    </template>
    <password-check-dialog
      :showPasswordDialog="showPasswordCheckDialog"
      key="passwordCheckDialog"
      @accept="onPasswordAccept"
      @reject="closeDialog"
    />
    <system-user-authentication-dialog
      :showDialog="showDialog"
      :show-update-password="showUpdatePassword"
      :show2Fak="show2Fak"
      :show2FakRecoveryCodes="show2FakRecoveryCodes"
      :qrCode="qrCode"
      :recoveryCodes="recoveryCodes"
      @reject="closeDialog"
      @accept="closeDialog"
    ></system-user-authentication-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SystemUsersService from "../services/systemUsers.service";
import ClinicsTable from "../components/LicSrvComponents/ClinicsTable.vue";
import NotificationService from "../services/notifications.service";
import PasswordCheckDialog from "../components/LicSrvComponents/PasswordCheckDialog";
import SystemUserAuthenticationDialog
  from "../components/LicSrvComponents/SystemUserAuthenticationDialog";
import ClinicDropdown from "../components/LicSrvComponents/ClinicDropdown.vue";
import ClinicsService from "../services/clinics.service";
import SqlStatementsService from "../services/sqlStatements.service";

export default {
  name: "SystemUserDashboard",
  components: {
    ClinicDropdown,
    SystemUserAuthenticationDialog,
    PasswordCheckDialog,
    ClinicsTable
  },
  data() {
    return {
      // Visualization
      dashboardDataLoading: true,
      dashboardDataLoadingText: "",
      fetchAll: false,
      // Dashboard data
      systemUser: {
        username: "",
        firstName: "",
        lastName: ""
      },
      notifications: [],
      filterData: {},
      firstFetchLimit: 10,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeated: "",
      showDialog: false,
      showUpdatePassword: false,
      //2 FA
      qrCode: "",
      password: "",
      show2Fak: false,
      show2FakRecoveryCodes: false,
      recoveryCodes: null,
      showPasswordCheckDialog: false,
      hasClinicReadPermission: false, // check if the dashboard is allowed to display/load the clinic table
      clinicNames: [],
      selectedClinic: "",
    };
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions([], this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          if (this.isLoggedIn) {
            this.fetchSystemUserDashboardData();
            this.filterData.systemUserIDs = [this.getUser];
            // check if the user has the permission to read the clinics for dashboard-overview
            this.$userPermissions.fetchCurrentUserPermissions([["read_Customer Administration_Clinic Administration"]], this.$store)
              .then((hasClinicReadPermission) => {
                this.hasClinicReadPermission = !!hasClinicReadPermission;
                this.dashboardDataLoading = false;
              })
              .catch(() => {
                this.$router.push({ name: "NotFound" });
              });
          }
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      });

    this.getSelectData();
  },
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"]),
    getDashboardHeading() {
      return `${this.systemUser.username} - Dashboard`;
    },
    getFilterData() {
      return this.filterData;
    },
    getDashboardDataLoadingSpinnerText() {
      return this.dashboardDataLoadingText;
    },
    formatRecoveryCodes() {
      let shortcutContent = "";
      Object.keys(this.recoveryCodes).forEach((key) => {
        shortcutContent += `${this.recoveryCodes[key]}\n\n`;
      });
      return shortcutContent;
    },
  },

  methods: {
    goToClinicViewDropdown(id) {
      this.$router.push({ name: 'ClinicView', params: { id } });
    },
    async getSelectData() {
      try {
        const clinicResponse = await ClinicsService.getCompressedClinicOverview();
        clinicResponse.data.forEach(clinic => {
          this.clinicNames.push({
            value: clinic.ClinicID,
            text: `${clinic.name} / ${clinic.clinicUUID} ${clinic.shortname? " / " + clinic.shortname : ''} / ${clinic.customerNumber}`,
            description: clinic.Organization.name
          });
        });
      } catch(error) {
        this.$toast.error(error.response.data);
      }
    },
    fetchSystemUserDashboardData() {
      this.dashboardDataLoading = true;
      this.dashboardDataLoadingText = "Loading assigned clinics";
      // fetch system user data
      SystemUsersService.getOwnSystemUserData(this.getUser).then((response) => {
        this.systemUser.username = response.data.username;
        this.systemUser.firstName = response.data.firstName;
        this.systemUser.lastName = response.data.lastName;
        this.fetchNotifications();
      });
    },
    switchFetchAmount() {
      this.fetchAll = !this.fetchAll;
      this.fetchNotifications();
    },
    fetchNotifications() {
      NotificationService.getNotificationsBySystemUser(this.getUser, this.fetchAll ? null : this.firstFetchLimit).then((notificationResponse) => {
        this.notifications = notificationResponse.data.notifications;
        this.$store.commit("badges/setNotificationBadge", {
          cnt: notificationResponse.data.totalNotifications,
          toast: false
        });
      });
    },
    get2FakQRCode() {
      SystemUsersService.getQRandRecoveryCodes2FA()
        .then((response) => {
          this.qrCode = response.data.otpUri;
          this.show2Fak = true;
          this.showUpdatePassword = false;
        })
        .catch(() => {
          this.$toast.error("Unable to show 2FA QR code. Please try again");
        });
    },
    getRecoveryCodes() {
      SystemUsersService.getQRandRecoveryCodes2FA()
        .then((response) => {
          this.recoveryCodes = JSON.parse(response.data.twoFactorRecoveryCodes);
          this.show2FakRecoveryCodes = true;
          this.showUpdatePassword = false;
        })
        .catch(() => {
          this.$toast.error("Unable to show recovery codes. Please try again");
        });
    },
    onPasswordAccept() {
      if (this.show2Fak) {
        this.get2FakQRCode();
        this.showPasswordCheckDialog = false;
        this.showDialog = true;
      }
      if (this.show2FakRecoveryCodes) {
        this.getRecoveryCodes();
        this.showPasswordCheckDialog = false;
        this.showDialog = true;
      }
    },
    closeDialog() {
      this.show2Fak = false;
      this.show2FakRecoveryCodes = false;
      this.showDialog = false;
      this.recoveryCodes = "";
      this.qrCode = "";
      this.showPasswordCheckDialog = false;
    },

    getNotificationColor(notification) {
      switch (notification.NotificationTypeID) {
        case 1:
          return notification.markedRead ? "success lighten-4 notificationRead" : "success notificationUnread";
        case 2:
          return notification.markedRead ? "warning lighten-4 notificationRead" : "warning notificationUnread";
        case 3:
          return notification.markedRead ? "error lighten-4 notificationRead" : "error notificationUnread";
        case 4:
          return notification.markedRead ? "info lighten-4 notificationRead" : "info notificationUnread";
        default:
          return notification.markedRead ? "info lighten-4 notificationRead" : "info notificationUnread";
      }
    },
    getNotificationHeader(notification) {
      let header = "";
      if (notification.Organization) {
        header += notification.Organization.name;
      }
      if (notification.Clinic) {
        header += ` | ${notification.Clinic.name}`;
      }
      if (notification.Location) {
        header += ` |  ${notification.Location.name}`;
      }

      return header;
    },
    getNotificationIcon(notification) {
      switch (notification.NotificationTypeID) {
        case 1:
          return "mdi-check";
        case 2:
          return "mdi-alert";
        case 3:
          return "mdi-alert-circle-outline";
        case 4:
          return "mdi-information";
        default:
          return "mdi-information";
      }
    },
    goToOrganizationView(notification) {
      this.$router.push({
        name: "OrganizationView",
        params: { id: notification.Organization.OrganizationID }
      });
    },
    goToClinicView(notification) {
      this.$router.push({ name: "ClinicView", params: { id: notification.Clinic.ClinicID } });
    },
    goToLocationView(notification) {
      this.$router.push({ name: "LocationView", params: { id: notification.Location.LocationID } });
    },
    deleteNotification(notification) {
      NotificationService.deleteNotification(notification.NotificationID)
        .then(() => {
          this.fetchNotifications();
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    updateNotification(notification) {
      NotificationService.updateNotification(notification.NotificationID, !notification.markedRead)
        .then(() => {
          this.fetchNotifications();
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    switch(cycleTypeGroupIdx, direction) {
      const temp = this.notifications[cycleTypeGroupIdx];
      const tempArray = [...this.notifications];
      tempArray[cycleTypeGroupIdx] = tempArray[cycleTypeGroupIdx + direction];
      tempArray[cycleTypeGroupIdx + direction] = temp;

      this.notifications = tempArray;
    },
    switchUp(cycleTypeGroupIdx) {
      this.switch(cycleTypeGroupIdx, -1);
    },
    switchDown(cycleTypeGroupIdx) {
      this.switch(cycleTypeGroupIdx, 1);
    }
  }
};
</script>

<style scoped>
.notificationSheetInner {
  box-shadow: inset 0 0 5px var(--v-primary-darken2) !important;
}

.notificationTransition-enter,
.notificationTransition-leave-to {
  opacity: 0;
}

.notificationTransition-enter-active,
.notificationTransition-leave-active {
  transition: opacity 0.5s ease;
}

.notificationTransition-move {
  transition: transform 0.5s ease-out;
}
</style>
